<template>
<!-- 设备管理 -->
  <div style="background:#eee">
    <div class="page-scan">
      <!--返回-->
      <van-nav-bar title="扫描二维码/条形码" fixed
                   @click-left="clickIndexLeft()"
                    >
        <template #left>
          <van-icon name="arrow-left" size="18" color="#1989fa"/>
          <span style="color: #1989fa"> 取消 </span>
        </template>
      </van-nav-bar>
      <!-- 扫码区域 -->
      <video ref="video" id="video" class="scan-video" autoplay></video>
      <!-- 提示语 -->
      <div v-show="tipShow" class="scan-tip"> {{tipMsg}} </div>
    </div>

  </div>
</template>
<script>
import { BrowserMultiFormatReader } from "@zxing/library";

export default {
  name: "scanCodePage",
  data() {
    return {
      loadingShow: false,
      codeReader: null,
      scanText: "",
      vin: null,
      tipMsg: "正在尝试识别....",
      tipShow: false,
      orderId:sessionStorage.getItem("orderId"),
      isOpenScan:this.$route.query.isOpenScan !== undefined,
      codeType:this.$route.query.codeType
    }
  },
  created() {
    if(this.isOpenScan){
      this.codeReader = new BrowserMultiFormatReader();
      this.openScan();
    }

  },
  destroyed(){
    this.codeReader.reset();
  },
  watch: {
    "$route"(to) {
      if(to.path === "/QCode"){
        this.codeReader = new BrowserMultiFormatReader();
        this.openScanTwo();
      }
    }
  },
  methods: {
    async openScan() {
      this.codeReader.getVideoInputDevices().then((videoInputDevices) => {
        this.tipShow = true;
        this.tipMsg = "正在调用摄像头...";
        // 默认获取第一个摄像头设备id
        let firstDeviceId = videoInputDevices[0].deviceId;
        // 获取第一个摄像头设备的名称
        const videoInputDeviceslablestr = JSON.stringify(videoInputDevices[0].label);
        if (videoInputDevices.length > 1) {
          // 判断是否后置摄像头
          if (videoInputDeviceslablestr.indexOf("back") > -1) {
            firstDeviceId = videoInputDevices[0].deviceId;
          } else {
            firstDeviceId = videoInputDevices[1].deviceId;
          }
        }
        this.decodeFromInputVideoFunc(firstDeviceId);
      }).catch(err => {
        this.tipShow = false;
        console.error(err);
      });
    },
    async openScanTwo() {
      this.codeReader = await new BrowserMultiFormatReader();
      this.codeReader.getVideoInputDevices().then((videoInputDevices) => {
        this.tipShow = true;
        this.tipMsg = "正在调用摄像头...";
        // 默认获取第一个摄像头设备id
        let firstDeviceId = videoInputDevices[0].deviceId;
        // 获取第一个摄像头设备的名称
        const videoInputDeviceslablestr = JSON.stringify(videoInputDevices[0].label);
        if (videoInputDevices.length > 1) {
          // 判断是否后置摄像头
          if (videoInputDeviceslablestr.indexOf("back") > -1) {
            firstDeviceId = videoInputDevices[0].deviceId;
          } else {
            firstDeviceId = videoInputDevices[1].deviceId;
          }
        }
        this.decodeFromInputVideoFunc(firstDeviceId);
      }).catch(err => {
        this.tipShow = false;
        console.error(err);
      });
    },
    decodeFromInputVideoFunc(firstDeviceId) {
      this.codeReader.reset(); // 重置
      this.scanText = "";
      this.codeReader.decodeFromInputVideoDeviceContinuously(firstDeviceId, "video", (result, err) => {
        this.tipMsg = "正在尝试识别...";
        this.scanText = "";
        if (result) {
          this.scanText = result.text;
          if(!this.isOpenScan){
            return
          }
          if (this.scanText) {
            this.tipShow = false;
            this.clickIndexLeft()
            // 这部分接下去的代码根据需要，读者自行编写了
            // this.$store.commit("app/SET_SCANTEXT", result.text);
            // console.log("已扫描的小票列表", this.$store.getters.scanTextArr);
          }
        }
        if (err && !(err)) {
          this.tipMsg = "识别失败";
          setTimeout(() => {
            this.tipShow = false;
          }, 2000)
          console.error(err);
        }
      });
    },
    clickIndexLeft(){  // 返回上一页
      this.codeReader = null;
      this.$destroy();
      this.isOpenScan = false
      if(this.codeType === 'orderCode'){
        /*this.$route.params.deviceNum = this.scanText;
        this.$route.params.row = this.row;
        this.$router.go(-1);*/
        let scanText = this.scanText
        this.scanText = ""
        this.$router.push({path:'/CompleteOrder',query: {deviceNum:scanText}})
      }else {
        this.$router.push({path:'/DeviceManage',query: {showAdd:true,deviceNum:this.scanText}})
      }
    }
  }
}
</script>

<style >
.scan-video{
  height: 80vh;
}
.scan-tip{
  width: 100vw;
  text-align: center;
  margin-bottom: 10vh;
  color: white;
  font-size: 5vw;
}
.page-scan{
  overflow-y: hidden;
  background-color: #363636;
}
</style>